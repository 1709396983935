import { classNames } from "~/utils/formatters";

export const Card = <TElement extends React.ElementType>(
  props: {
    as?: TElement;
    topContent?: React.ReactNode;
  } & Omit<React.ComponentPropsWithoutRef<TElement>, "as" | "topContent">,
) => {
  const { as: Component = "div", topContent, children, ...elProps } = props;

  return (
    <Component
      {...elProps}
      className={classNames("card", elProps.className ?? "")}
    >
      {topContent ? (
        <div className="absolute inset-x-0 -top-9 grid place-items-center">
          {topContent}
        </div>
      ) : null}
      {children}
    </Component>
  );
};
